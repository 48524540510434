import React from 'react'
import { Message } from 'semantic-ui-react'

export function Notification (props) {
  return <Message
    {...props}
    negative
    style={{
      position: 'absolute',
      bottom: '5vh',
      right: '5vw',
      width: '90vw',
      maxWidth: 1000,
    }}
  />
}
