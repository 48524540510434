import React, { createContext, useState } from 'react'

import { getMe } from './api'

export const AuthContext = createContext()
export const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({})
  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  )
}

const TOKEN_KEY = 'token'
export async function syncAuth (newToken, save) {
  const savedToken = window.sessionStorage.getItem(TOKEN_KEY)
  const token = newToken || savedToken
  if (token) {
    const merchant = await getMe(token)
    save({ merchant, token })
    window.sessionStorage.setItem(TOKEN_KEY, token)
    return true
  }
  window.sessionStorage.removeItem(TOKEN_KEY)
  return false
}

export function destroyAuth (save) {
  save({ merchant: null, token: null })
  window.sessionStorage.removeItem(TOKEN_KEY)
}
