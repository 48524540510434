import React, { useEffect, useState } from 'react'
import { Form, Grid, Header, Segment, Select, TextArea } from 'semantic-ui-react'
import { map } from 'ramda'

import { Main } from '../component'
import { sign } from '../util'

export function Utilities () {
  return (
    <Main>
      <Header as='h1'>Utilities</Header>
      <Signature />
    </Main>
  )
}

const ALGOS = ['sha512', 'sha384', 'sha256']

function Signature () {
  const [algo, setAlgo] = useState(null)
  const [body, setBody] = useState(null)
  const [secret, setSecret] = useState(null)
  const [code, setCode] = useState(null)
  const [signature, setSignature] = useState(null)

  const mapToAlgos = map(a => ({ key: a, value: a, text: a, onClick () { setAlgo(a) } }))

  useEffect(() => {
    if (body && secret && algo && ALGOS.includes(algo)) {
      setCode(`crypto.createHmac(${algo}, ${secret}).update(${body}).digest('hex')`)
      setSignature(`${algo}=${sign(secret, body, algo)}`)
    } else {
      setCode(`crypto.createHmac(${algo || '<algo>'}, ${secret || '<secret>'}).update(${body || '<body>'}).digest('hex')`)
      setSignature(null)
    }
  }, [algo, body, secret])

  const monospace = { fontFamily: 'monospace' }
  const wrap = { overflowWrap: 'anywhere' }

  return (
    <Segment>
      <Header as='h2'>Signature Calculator</Header>
      <Form>
        <Grid columns={2} stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={2}>Secret</Grid.Column>
            <Grid.Column width={14}>
              <TextArea
                rows={1}
                value={secret}
                onChange={(e) => setSecret(e.target.value)}
                style={monospace}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>Body</Grid.Column>
            <Grid.Column width={14}>
              <TextArea
                value={body}
                onChange={(e) => setBody(e.target.value)}
                style={monospace}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>Algorithm</Grid.Column>
            <Grid.Column width={14}>
              <Select
                value={algo}
                options={mapToAlgos(ALGOS)}
                onChange={e => setAlgo(e.target.value)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>Javascript</Grid.Column>
            <Grid.Column width={14} style={{ ...wrap, ...monospace }}>
              {code}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>Signature</Grid.Column>
            <Grid.Column width={14} style={{ ...wrap, ...monospace }}>
              {signature}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  )
}
