import React, { useContext, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'
import { AuthContext, syncAuth } from '../auth-context'
import { Notification } from '../component'
import { HOME } from '../path'

export function Login () {
  const [auth, setAuth] = useContext(AuthContext)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const history = useHistory()

  if (auth.token) return <Redirect to={HOME} />
  const onClick = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (await syncAuth(token, setAuth)) {
        history.push(HOME)
      } else {
        setError('Empty token.')
      }
    } catch (err) {
      setError(err.message)
    }
    setLoading(false)
  }

  const onChange = (e) => {
    setError('')
    setToken(e.target.value)
  }

  return (
    <>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as='h2' textAlign='center'>
            Log-in to your merchant
          </Header>
          <Form size='large'>
            <Segment stacked>
              <Form.Input
                error={!!error}
                fluid
                icon='lock'
                iconPosition='left'
                onChange={onChange}
                placeholder='API token'
                value={token}
              />
              <Button
                disabled={!token}
                fluid
                loading={loading}
                onClick={onClick}
                primary
                size='large'
              >
                Login
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
      <Notification hidden={!error}>{error}</Notification>
    </>
  )
}
