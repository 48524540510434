import React, { useContext, useEffect, useState } from 'react'
import { Button, Divider, Header, Input, Message, Modal, Select, Table } from 'semantic-ui-react'
import { map, prop } from 'ramda'

import { AuthContext } from '../auth-context'
import { estimateWithdrawal, getBalances, getWithdrawal, getWithdrawals, postWithdrawal } from '../api'
import { LoadableData, Main } from '../component'
import { formatEpoch, formatMono, rand } from '../util'

export function Withdrawals () {
  return (
    <Main>
      <Header as='h1'>Withdrawals</Header>
      <CreateWithdrawal />
      <Divider horizontal />
      <LoadableData
        getData={getWithdrawals}
        getDatum={getWithdrawal}
        HeadRow={WithdrawalHead}
        DatumRow={WithdrawalRow}
        DetailModal={WithdrawalDetail}
      />
    </Main>
  )
}

function WithdrawalHead () {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Coin</Table.HeaderCell>
        <Table.HeaderCell>Withdrawal Key</Table.HeaderCell>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.HeaderCell>Fee</Table.HeaderCell>
        <Table.HeaderCell>Creation Time</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  )
}

function WithdrawalRow ({ datum, onClick }) {
  return (
    <Table.Row style={{ cursor: 'pointer' }} onClick={(e) => onClick(e, datum)}>
      <Table.Cell>{formatMono(datum.id)}</Table.Cell>
      <Table.Cell>{datum.coin}</Table.Cell>
      <Table.Cell>{formatMono(datum.withdrawal_key)}</Table.Cell>
      <Table.Cell>{datum.amount}</Table.Cell>
      <Table.Cell>{datum.fee}</Table.Cell>
      <Table.Cell>{formatEpoch(datum.created_at)}</Table.Cell>
      <Table.Cell>{datum.status}</Table.Cell>
    </Table.Row>
  )
}

function WithdrawalDetail ({ detail, close }) {
  if (!detail) return null
  return (
    <Modal open={!!detail} onClose={close}>
      <Modal.Header>{detail.id}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
        <Table stackable striped basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.id)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Coin</Table.HeaderCell>
              <Table.Cell>{detail.coin}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Withdrawal Key</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.withdrawal_key)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Transaction Hash</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.transaction_hash)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.address)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Tag</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.tag)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.Cell>{detail.amount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Fee</Table.HeaderCell>
              <Table.Cell>{detail.fee}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Notification Link</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.notification_link)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Creation Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.created_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell>{detail.status}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Confirmation Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.confirmed_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Notification Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.notified_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Last Trial Notification Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.last_tried_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Failed Notification Count</Table.HeaderCell>
              <Table.Cell>{detail.error_count}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Last Failed Notification Status</Table.HeaderCell>
              <Table.Cell>{prop('status')(detail.last_error)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Last Failed Notification Text</Table.HeaderCell>
              <Table.Cell>{prop('body')(detail.last_error)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close} primary>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

function CreateWithdrawal () {
  const [auth] = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [balances, setBalances] = useState([])
  const [error, setError] = useState(null)
  const [coin, setCoin] = useState(null)
  const [amount, setAmount] = useState('')
  const [address, setAddress] = useState('')
  const [tag, setTag] = useState('')
  const [secret, setSecret] = useState('')
  const close = () => setOpen(false)
  const submit = async () => {
    try {
      const key = rand()
      await postWithdrawal(auth.token, secret, {
        address,
        amount,
        coin,
        tag: tag || null,
        withdrawal_key: key,
      })
      close()
    } catch (err) {
      setError(err.message)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        setBalances(await getBalances(auth.token))
      } catch (err) {
        setError(err.message)
      }
    })()
  }, [auth.token])
  const mapToCoins = map(({ coin: c }) => ({ key: c, value: c, text: c, onClick () { setCoin(c) } }))
  const getFree = (coin) => {
    if (coin) {
      const balance = balances.filter(({ coin: c }) => c === coin)
      if (balance.length === 1) return balance[0].free
    }
    return '-'
  }
  return (
    <>
      <Button onClick={() => setOpen(true)}>Make a new withdrawal</Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Make a withdrawal</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
          <Table stackable striped basic='very'>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell>Coin</Table.HeaderCell>
                <Table.Cell>
                  <Select
                    value={coin}
                    options={mapToCoins(balances)}
                    onChange={e => setCoin(e.target.value)}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.Cell><Input value={amount} onChange={(e) => setAmount(e.target.value)}/> max: {getFree(coin)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Estimated Fee</Table.HeaderCell>
                <Table.Cell><Fee amount={amount} coin={coin} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.Cell><Input fluid value={address} onChange={e => setAddress(e.target.value)} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Tag</Table.HeaderCell>
                <Table.Cell><Input value={tag} onChange={e => setTag(e.target.value)} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>API Secret</Table.HeaderCell>
                <Table.Cell><Input fluid value={secret} onChange={e => setSecret(e.target.value)} /></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Message negative hidden={!error}>{error}</Message>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={submit} primary>Submit</Button>
          <Button onClick={close} secondary>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

function Fee ({ coin, amount }) {
  const [auth] = useContext(AuthContext)
  const [fee, setFee] = useState('-')
  useEffect(() => {
    if (coin && amount) {
      (async () => {
        try {
          const { fee } = await estimateWithdrawal(auth.token, { amount, coin })
          setFee(fee)
        } catch (err) {
          setFee('-')
        }
      })()
      console.error('estimate fee for', coin, amount)
    } else {
      setFee('-')
    }
  }, [auth.token, amount, coin])
  return (<>{fee}</>)
}
