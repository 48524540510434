import React from 'react'
import { DateTime } from 'luxon'

export function formatEpoch (timestamp) {
  return timestamp ? DateTime.fromMillis(timestamp).toISO() : null
}

export function formatMono (id) {
  return (<div style={{ fontFamily: 'monospace' }}>{id}</div>)
}
