import { createMedia } from '@artsy/fresnel'
import React, { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { Button, Container, Header, Loader, Message, Table } from 'semantic-ui-react'

import { getPaymentAccount, getPaymentOrder } from './api'
import './i18n';
import { formatEpoch } from './util'
import { breakpoints } from './media'

const { MediaContextProvider, Media } = createMedia({ breakpoints })

export default function Payment () {
  return (
    <Switch>
      <Route path="/payment/accounts/:id"><Page type='account' /></Route>
      <Route path="/payment/orders/:id"><Page type='order' /></Route>
    </Switch>
  )
}

function Page ({ type }) {
  const { id } = useParams()
  // data:
  //   undefined: loading
  //   null: notfound
  //   object: show payment info
  const [data, setData] = useState(undefined)
  const [error, setError] = useState(null)
  useEffect(() => {
    ;(async () => {
      try {
        const getData = { account: getPaymentAccount, order: getPaymentOrder }
        setData(await getData[type](id))
      } catch (err) {
        setError(err.message)
      }
    })()
  }, [id, type])

  return (
    <div>
      <Container text textAlign='center' style={{ marginTop: 60 }}>
        {data === undefined && <Loader />}
        {data === null && <NotFound />}
        {!!data && <Detail data={data} type={type} />}
        {!!error && <Message negative>{error}</Message>}
      </Container>
    </div>
  )
}

function NotFound () {
  return <Header as='h1'>Not found</Header>
}

function Detail ({ data, type }) {
  const { t } = useTranslation();
  const { merchant, coin } = data
  const datum = data[type]
  return (
    <MediaContextProvider>
      <Header as='h2'>{merchant.name}</Header>
      <Address address={datum.address} />
      <Table stackable basic='very'>
        <Table.Body>
          <Table.Row>
            <TableTitle width='one'>{t('coin')}</TableTitle>
            <TableValue width='one'>{coin.name}</TableValue>
          </Table.Row>
          {datum.tag && <Table.Row>
            <TableTitle>{t('tag')}</TableTitle>
            <TableValue>{datum.tag}</TableValue>
          </Table.Row>}
          {datum.amount && <Table.Row>
            <TableTitle>{t('amount')}</TableTitle>
            <TableValue>{datum.amount}</TableValue>
          </Table.Row>}
          {datum.expired_at && <Table.Row>
            <TableTitle>{t('expiration')}</TableTitle>
            <TableValue>{formatEpoch(datum.expired_at)}</TableValue>
          </Table.Row>}
        </Table.Body>
      </Table>
    </MediaContextProvider>
  )
}

function TableTitle ({ children, ...rest }) {
  return (
    <Table.HeaderCell {...rest}>
      <Media at='mobile'>
        <Container fluid textAlign='left'>{children}</Container>
      </Media>
      <Media greaterThan='mobile'>
        <Container fluid textAlign='right'>{children}</Container>
      </Media>
    </Table.HeaderCell>
  )
}

function TableValue ({ children, ...rest }) {
  return (
    <Table.Cell {...rest}>
      <Container fluid textAlign='left'>{children}</Container>
    </Table.Cell>
  )
}

function Address ({ address }) {
  const [name, setName] = useState('copy')
  const { t } = useTranslation()

  return (
    <>
      <Header as='h3'>{t('address')}</Header>
      <QRCode className="qrcode" value={address} size={256} />
      <div className="address">{address}</div>
      <CopyToClipboard text={address} onCopy={() => setName('copied')}>
        <Button primary>{t(name)}</Button>
      </CopyToClipboard>
    </>
  )
}
