import React, { useState } from 'react'
import GoogleFontLoader from 'react-google-font-loader';
import { RedocStandalone } from 'redoc';


export default function Payment () {
  const [locale, setLocale] = useState('en')
  const locales = ['en', 'zh-tw', 'zh-cn']
  const onSelectLocale = e => setLocale(e.target.value)
  return (
    <>
      <select onChange={onSelectLocale}>
        {locales.map(l => (<option key={`locale-${l}`}>{l}</option>))}
      </select>
      <GoogleFontLoader
        fonts={[
          { font: 'Roboto', weights: [400, 400, 700] },
          { font: 'Montserrat', weights: [300, 400, 700] },
        ]}
      />
      <RedocStandalone specUrl={`/${locale}.yaml`} />
    </>
  )
}
