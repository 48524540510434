import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Container,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { AuthContext, AuthContextProvider, syncAuth } from './auth-context'
import { Accounts, Balances, Deposits, Orders, Utilities, Withdrawals, Login, Logout } from './route'
import { emitter } from './event'
import { ACCOUNTS, BALANCES, DEPOSITS, HOME, LOGIN, LOGOUT, ORDERS, UTILITIES, WITHDRAWALS } from './path'
import { breakpoints } from './media'
import { Main } from './component'

const { MediaContextProvider, Media } = createMedia({ breakpoints })

function DesktopContainer ({ children }) {
  const [{ token }] = useContext(AuthContext)

  return (
    <Media greaterThan='mobile'>
      <Segment textAlign='center' vertical>
        <Menu fixed='top' size='large'>
            <Container>
              <MenuItemLink to={HOME}>Home</MenuItemLink>
              <MenuItemLink to={BALANCES}>Balances</MenuItemLink>
              <MenuItemLink to={ACCOUNTS}>Accounts</MenuItemLink>
              <MenuItemLink to={ORDERS}>Orders</MenuItemLink>
              <MenuItemLink to={DEPOSITS}>Deposits</MenuItemLink>
              <MenuItemLink to={WITHDRAWALS}>Withdrawals</MenuItemLink>
              <MenuItemLink to={UTILITIES}>Utilities</MenuItemLink>
              <Menu.Item position='right'>
                {!token && <Button as={Link} to={LOGIN}>Log in</Button>}
                {token && <Button as={Link} to={LOGOUT} primary style={{ marginLeft: '0.5em' }}>
                  Log out
                </Button>}
              </Menu.Item>
            </Container>
          </Menu>
        </Segment>

      {children}
    </Media>
  )
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

function MobileContainer ({ children }) {
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [{ token }] = useContext(AuthContext)

  return (
    <Media as={Sidebar.Pushable} at='mobile'>
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation='overlay'
          onHide={() => setSidebarOpened(false)}
          vertical
          visible={sidebarOpened}
        >
          <MenuItemLink to={HOME}>Home</MenuItemLink>
          <MenuItemLink to={BALANCES}>Balances</MenuItemLink>
          <MenuItemLink to={ACCOUNTS}>Accounts</MenuItemLink>
          <MenuItemLink to={ORDERS}>Orders</MenuItemLink>
          <MenuItemLink to={DEPOSITS}>Deposits</MenuItemLink>
          <MenuItemLink to={WITHDRAWALS}>Withdrawals</MenuItemLink>
          <MenuItemLink to={UTILITIES}>Utilities</MenuItemLink>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment textAlign='center' vertical>
            <Container>
              <Menu secondary size='large'>
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  {!token && <Button as='a' to={LOGIN}>Log in</Button>}
                  {token && <Button as='a' to={LOGOUT} style={{ marginLeft: '0.5em' }}>Log out</Button>}
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  )
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

function Home () {
  return (
    <Main>
      <Header as='h1'>Home</Header>
    </Main>
  )
}

function Backstage () {
  return (
    <AuthContextProvider>
      <EventHandler>
        <Switch>
          <Route path={HOME} exact>
            <ResponsiveContainer>
              <Home />
            </ResponsiveContainer>
          </Route>
          <Route path={LOGIN}><Login /></Route>
          <AuthRoute path={LOGOUT}><Logout /></AuthRoute>
          <AuthRoute path={BALANCES}><Balances /></AuthRoute>
          <AuthRoute path={ACCOUNTS}><Accounts /></AuthRoute>
          <AuthRoute path={ORDERS}><Orders /></AuthRoute>
          <AuthRoute path={DEPOSITS}><Deposits /></AuthRoute>
          <AuthRoute path={WITHDRAWALS}><Withdrawals /></AuthRoute>
          <AuthRoute path={UTILITIES}><Utilities /></AuthRoute>
        </Switch>
      </EventHandler>
    </AuthContextProvider>
  )
}

function EventHandler ({ children }) {
  const history = useHistory()
  const [, setAuth] = useContext(AuthContext)
  useEffect(() => {
    syncAuth(null, setAuth).catch(console.error)
  }, [setAuth])
  useEffect(() => {
    const event = 'logout'
    const handler = () => {
      setAuth({})
      history.push(LOGIN)
    }
    emitter.on(event, handler)
    return () => emitter.off(event, handler)
  }, [history, setAuth])
  return children
}

function AuthRoute ({ children, ...rest }) {
  const [auth] = useContext(AuthContext)
  const to = from => ({ pathname: LOGIN, state: { from } })
  const render = ({ location }) => auth.token
    ? (<ResponsiveContainer>{children}</ResponsiveContainer>)
    : (<Redirect to={to(location)} />)
  return <Route {...rest} render={render} />
}

function MenuItemLink({ children, to, ...rest }) {
  const match = useRouteMatch({ path: to, exact: true })
  return (
    <Menu.Item as={Link} to={to} active={!!match} {...rest}>
      {children}
    </Menu.Item>
  )
}

export default Backstage
