import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const resources = {
  en: { // {{{
    translation: {
      address: 'Address',
      copy: 'Copy',
      copied: 'Copied',
      coin: 'Coin',
      tag: 'Tag',
      amount: 'Amount',
      expiration: 'Expiration'
    }
  }, // }}}
  'zh-TW': { // {{{
    translation: {
      address: '地址',
      copy: '複製',
      copied: '已複製',
      coin: '幣別',
      tag: '標籤',
      amount: '數量',
      expiration: '期限'
    }
  }, // }}}
  'zh-CN': { // {{{
    translation: {
      address: '地址',
      copy: '复制',
      copied: '已复制',
      coin: '币别',
      tag: '标籤',
      amount: '数量',
      expiration: '期限'
    }
  } // }}}
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: { escapeValue: false },
  });

export default i18n;
