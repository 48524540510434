import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext, destroyAuth } from '../auth-context'
import { LOGOUT } from '../path'

export function Logout () {
  const [, setAuth] = useContext(AuthContext)
  destroyAuth(setAuth)
  return <Redirect to={LOGOUT} />
}
