const BASE = '/backstage'
export const HOME = BASE
export const BALANCES = `${BASE}/balances`
export const ACCOUNTS = `${BASE}/accounts`
export const ORDERS = `${BASE}/orders`
export const DEPOSITS = `${BASE}/deposits`
export const WITHDRAWALS = `${BASE}/withdrawals`
export const UTILITIES = `${BASE}/utilities`
export const LOGIN = `${BASE}/login`
export const LOGOUT = `${BASE}/logout`
