import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

import Backstage from './Backstage'
import Doc from './Doc'
import Payment from './Payment'

function App () {
  return (
    <Router>
      <Switch>
        <Route path="/" exact><Header as='h1'>BISA</Header></Route>
        <Route path="/payment"><Payment /></Route>
        <Route path="/backstage"><Backstage /></Route>
        <Route path="/doc"><Doc /></Route>
      </Switch>
    </Router>
  )
}

export default App
