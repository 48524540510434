import React, { useContext, useEffect, useState } from 'react'
import { Header, Table } from 'semantic-ui-react'

import { AuthContext } from '../auth-context'
import { getBalances } from '../api'
import { Main, Notification } from '../component'

export function Balances () {
  const [balances, setBalances] = useState([])
  const [error, setError] = useState(null)
  const [auth] = useContext(AuthContext)
  useEffect(() => {
    ;(async () => {
      try {
        setBalances(await getBalances(auth.token))
      } catch (err) {
        setError(err.message)
      }
    })()
  }, [auth.token])

  return (
    <Main>
      <Header as='h1'>Balances</Header>
      <Table stackable striped basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Coin</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>Locked</Table.HeaderCell>
            <Table.HeaderCell>Free</Table.HeaderCell>
            <Table.HeaderCell>Free (ready)</Table.HeaderCell>
            <Table.HeaderCell>Free (pending)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {balances.map((balance, i) => <Balance key={i} balance={balance} />)}
        </Table.Body>
      </Table>
      <Notification hidden={!error}>{error}</Notification>
    </Main>
  )
}

function Balance ({ balance }) {
  return (
    <Table.Row>
      <Table.Cell>{balance.coin}</Table.Cell>
      <Table.Cell>{balance.total}</Table.Cell>
      <Table.Cell>{balance.locked}</Table.Cell>
      <Table.Cell>{balance.free}</Table.Cell>
      <Table.Cell>{balance.free_ready}</Table.Cell>
      <Table.Cell>{balance.free_pending}</Table.Cell>
    </Table.Row>
  )
}
