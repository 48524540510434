import React from 'react'
import { Button, Header, Modal, Table } from 'semantic-ui-react'
import { prop } from 'ramda'

import { getDeposit, getDeposits } from '../api'
import { LoadableData, Main } from '../component'
import { formatEpoch, formatMono } from '../util'

export function Deposits () {
  return (
    <Main>
      <Header as='h1'>Deposits</Header>
      <LoadableData
        getData={getDeposits}
        getDatum={getDeposit}
        HeadRow={DepositHead}
        DatumRow={DepositRow}
        DetailModal={DepositDetail}
      />
    </Main>
  )
}

function DepositHead () {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Coin</Table.HeaderCell>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.HeaderCell>Creation Time</Table.HeaderCell>
        <Table.HeaderCell>On-chain Time</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  )
}

function DepositRow ({ datum, onClick }) {
  return (
    <Table.Row style={{ cursor: 'pointer' }} onClick={(e) => onClick(e, datum)}>
      <Table.Cell>{formatMono(datum.id)}</Table.Cell>
      <Table.Cell>{datum.coin}</Table.Cell>
      <Table.Cell>{datum.amount}</Table.Cell>
      <Table.Cell>{formatEpoch(datum.created_at)}</Table.Cell>
      <Table.Cell>{formatEpoch(datum.on_chain_at)}</Table.Cell>
    </Table.Row>
  )
}

function DepositDetail ({ detail, close }) {
  if (!detail) return null
  return (
    <Modal open={!!detail} onClose={close}>
      <Modal.Header>{detail.id}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
        <Table stackable striped basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.id)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Coin</Table.HeaderCell>
              <Table.Cell>{detail.coin}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Transaction Hash</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.transaction_hash)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.address)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Tag</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.tag)}</Table.Cell>
            </Table.Row>
            {detail.account_id && <Table.Row>
              <Table.HeaderCell>Account ID</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.account_id)}</Table.Cell>
            </Table.Row>}
            {detail.account_key && <Table.Row>
              <Table.HeaderCell>Account Key</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.account_key)}</Table.Cell>
            </Table.Row>}
            {detail.order_id && <Table.Row>
              <Table.HeaderCell>Order ID</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.order_id)}</Table.Cell>
            </Table.Row>}
            {detail.order_key && <Table.Row>
              <Table.HeaderCell>Order Key</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.order_key)}</Table.Cell>
            </Table.Row>}
            <Table.Row>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.Cell>{detail.amount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Fee</Table.HeaderCell>
              <Table.Cell>{detail.fee}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Creation Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.created_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>On-chain Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.on_chain_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Confirmation Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.confirmed_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Notification Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.notified_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Last Trial Notification Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.last_tried_at)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Failed Notification Count</Table.HeaderCell>
              <Table.Cell>{detail.error_count}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Last Failed Notification Status</Table.HeaderCell>
              <Table.Cell>{prop('status')(detail.last_error)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Last Failed Notification Text</Table.HeaderCell>
              <Table.Cell>{prop('body')(detail.last_error)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close} primary>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}
