import React, { useContext, useState } from 'react'
import { Button, Grid, Header, Input, Segment, Table } from 'semantic-ui-react'
import { isEmpty, last, pick, prop, uniqBy } from 'ramda'
import { DateTime } from 'luxon'

import { AuthContext } from '../auth-context'
import { formatEpoch, formatMono } from '../util'
import { Notification } from './Notification'

export function LoadableData (props) {
  const {
    getData,
    getDatum,
    HeadRow,
    DatumRow,
    DetailModal,
  } = props

  const [type, setType] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadmore, setLoadmore] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [auth] = useContext(AuthContext)
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [input, setInput] = useState('')

  const onSearch = async () => {
    if (!input) return
    setLoading(true)
    setError(null)
    setType('search')
    try {
      const datum = await getDatum(auth.token, input)
      setData(datum ? [datum] : [])
    } catch (err) {
      setError(err.message)
    }
    setLoading(false)
  }

  const onFilter = async (from, to) => {
    const query = {}
    const fromDate = DateTime.fromISO(from)
    if (fromDate.isValid) query.from = fromDate.toMillis()
    const toDate = DateTime.fromISO(to)
    if (toDate.isValid) query.to = toDate.toMillis() + 86399999 // end of day
    if (isEmpty(query)) return
    setError(null)
    setType('filter')
    try {
      setData(await getData(auth.token, query))
      setLoadmore(true)
    } catch (err) {
      setError(err.message)
    }
  }

  const onLoadMore = async () => {
    const query = {}
    const fromDate = DateTime.fromISO(from)
    if (fromDate.isValid) query.from = fromDate.toMillis()
    if (!isEmpty(data)) query.to = last(data).created_at
    if (isEmpty(query)) return
    setError(null)
    try {
      const length = data.length
      const updated = (uniqBy(prop('id'))([
        ...data,
        ...(await getData(auth.token, query)),
      ]))
      setData(updated)
      setLoadmore(updated.length !== length)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <>
      <Action
        inputState={[input, setInput]}
        fromState={[from, setFrom]}
        toState={[to, setTo]}
        onSearch={onSearch}
        onFilter={onFilter}
        loading={loading}
        loadmore={loadmore}
      />
      <Data
        HeadRow={HeadRow}
        DatumRow={DatumRow}
        DetailModal={DetailModal}
        data={data}
      />
      {type === 'filter' && !isEmpty(data) && <Button
        disabled={!loadmore}
        onClick={onLoadMore}
      >Load more</Button>}
      <Notification hidden={!error}>{error}</Notification>
    </>
  )
}


function Empty () {
  return (
    <Segment>
      <Header as='h3' textAlign='center'>Empty</Header>
    </Segment>
  )
}

function Data ({ HeadRow, DatumRow, DetailModal, data }) {
  const [detail, setDetail] = useState(null)
  if (isEmpty(data)) return <Empty />
  const onClick = (e, datum) => setDetail(datum)
  return (
    <>
      <Table stackable striped basic='very'>
        <HeadRow />
        <Table.Body>
          {data.map((datum, i) => <DatumRow datum={datum} key={i} onClick={onClick} />)}
        </Table.Body>
      </Table>
      <DetailModal detail={detail} close={() => setDetail(null)} />
    </>
  )
}

function DateColumn (props) {
  return (<Grid.Column><Input fluid type='date' {...props} /></Grid.Column>)
}

function SearchColumn (props) {
  return (
    <Grid.Column>
      <Input fluid action={pick(['content', 'onClick', 'loading'])(props)} {...props} />
    </Grid.Column>
  )
}

function Action (props) {
  const {
    inputState: [input, setInput],
    fromState: [from, setFrom],
    toState: [to, setTo],
    onSearch,
    onFilter,
    loading,
  } = props
  return (
    <Grid columns='equal' stackable>
      <DateColumn
        label='From'
        value={from}
        onChange={(e) => {
          setFrom(e.target.value)
          onFilter(e.target.value, to)
        }}
      />
      <DateColumn
        label='To'
        value={to}
        onChange={(e) => {
          setTo(e.target.value)
          onFilter(from, e.target.value)
        }}
      />
      <SearchColumn
        content='Search'
        onClick={onSearch}
        placeholder='ID'
        value={input}
        onChange={e => setInput(e.target.value)}
        loading={loading}
      />
    </Grid>
  )
}

export function DepositHistory ({ deposits }) {
  if (isEmpty(deposits)) return null
  return (
    <>
      <Header as='h3'>Deposit History</Header>
      <Table stackable striped basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Coin</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>On-chain Time</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {deposits.map((deposit) => (
            <Table.Row>
              <Table.Cell>{formatMono(deposit.id)}</Table.Cell>
              <Table.Cell>{deposit.coin}</Table.Cell>
              <Table.Cell>{deposit.amount}</Table.Cell>
              <Table.Cell>{formatEpoch(deposit.on_chain_at)}</Table.Cell>
            </Table.Row>)
          )}
        </Table.Body>
      </Table>
    </>
  )
}
