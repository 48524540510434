import { emitter } from './event'

import { sign } from './util'


export function getMe (token) {
  return get(token, 'me', {}, false)
}

export function getAccount (token, id) {
  return getOrNull(token, `accounts/${id}`)
}

export function getAccounts (token, query) {
  return get(token, 'accounts', query)
}

export function getOrder (token, id) {
  return getOrNull(token, `orders/${id}`)
}

export function getOrders (token, query) {
  return get(token, 'orders', query)
}

export function getDeposit (token, id) {
  return getOrNull(token, `deposits/${id}`)
}

export function getDeposits (token, query) {
  return get(token, 'deposits', query)
}

export function getAccountDeposits (token, id) {
  return get(token, 'deposits', { account_id: id })
}

export function getOrderDeposits (token, id) {
  return get(token, 'deposits', { order_id: id })
}

export function getWithdrawal (token, id) {
  return getOrNull(token, `withdrawals/${id}`)
}

export function getWithdrawals (token, query) {
  return get(token, 'withdrawals', query)
}

export function getPaymentAccount (id) {
  return getOrNull(null, `payment/accounts/${id}`)
}

export function getPaymentOrder (id) {
  return getOrNull(null, `payment/orders/${id}`)
}

export function getBalances (token) {
  return get(token, 'balances')
}

export function postWithdrawal (token, secret, body) {
  return post(token, secret, 'withdrawals', body)
}

export function estimateWithdrawal (token, query) {
  return get(token, 'withdrawals/estimate', query)
}

async function get (token, path, query = {}, auth = true) {
  const qs = new URLSearchParams()
  Object.entries(query).forEach(([key, value]) => qs.append(key, value))
  const res = await fetch(`/api/${path}?${qs.toString()}`, {
    method: 'GET',
    headers: { 'x-bisa-version': '1', 'x-bisa-token': token },
  })
  const body = await res.json()
  if (res.ok) return body

  if (res.status === 401 && auth) return emitter.emit('logout')
  const err = new Error(body.message)
  err.status = body.status
  err.code = body.code
  err.name = body.name
  throw err
}

async function getOrNull (...args) {
  try {
    return await get(...args)
  } catch (err) {
    if (isNotFound(err)) return null
    throw err
  }
}

async function post (token, secret, path, values = {}, query = {}) {
  const qs = new URLSearchParams()
  Object.entries(query).forEach(([key, value]) => qs.append(key, value))
  const body = JSON.stringify({ timestamp: Date.now(), ...values })
  const res = await fetch(`/api/${path}?${qs.toString()}`, {
    method: 'POST',
    headers: {
      'x-bisa-version': '1',
      'x-bisa-token': token,
      'x-bisa-signature': `sha512=${sign(secret, body)}`,
      'content-type': 'application/json',
    },
    body,
  })
  const json = await res.json()
  if (res.ok) return json

  if (res.status === 401) return emitter.emit('logout')
  const err = new Error(json.message)
  err.status = json.status
  err.code = json.code
  err.name = json.name
  throw err
}

function isNotFound (err) {
  return err.code === 'E:404:0'
}
