import React, { useContext, useEffect, useState } from 'react'
import { Button, Header, Modal, Table } from 'semantic-ui-react'

import { AuthContext } from '../auth-context'
import { getAccount, getAccounts, getAccountDeposits } from '../api'
import { BlankLink, DepositHistory, LoadableData, Main } from '../component'
import { formatEpoch, formatMono } from '../util'

export function Accounts () {
  return (
    <Main>
      <Header as='h1'>Accounts</Header>
      <LoadableData
        getData={getAccounts}
        getDatum={getAccount}
        HeadRow={AccountHead}
        DatumRow={AccountRow}
        DetailModal={AccountDetail}
      />
    </Main>
  )
}

function AccountHead () {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Coin</Table.HeaderCell>
        <Table.HeaderCell>Account Key</Table.HeaderCell>
        <Table.HeaderCell>Creation Time</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  )
}

function AccountRow ({ datum , onClick }) {
  return (
    <Table.Row
      style={{ cursor: 'pointer' }}
      onClick={(e) => onClick(e, datum)}
    >
      <Table.Cell>{formatMono(datum.id)}</Table.Cell>
      <Table.Cell>{datum.coin}</Table.Cell>
      <Table.Cell>{formatMono(datum.account_key)}</Table.Cell>
      <Table.Cell>{formatEpoch(datum.created_at)}</Table.Cell>
    </Table.Row>
  )
}

function AccountDetail ({ detail, close }) {
  const [auth] = useContext(AuthContext)
  const [deposits, setDeposits] = useState([])
  useEffect(() => {
    (async () => {
      try {
        setDeposits(await getAccountDeposits(auth.token, detail.id))
      } catch (err) {
      }
    })()
  }, [auth.token, detail])

  if (!detail) return null
  return (
    <Modal open={!!detail} onClose={close}>
      <Modal.Header>{detail.id}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
        <Table stackable striped basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.id)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Coin</Table.HeaderCell>
              <Table.Cell>{detail.coin}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Account Key</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.account_key)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.address)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Tag</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.tag)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Notification Link</Table.HeaderCell>
              <Table.Cell>{formatMono(detail.notification_link)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Payment Link</Table.HeaderCell>
              <Table.Cell>
								<BlankLink href={detail.payment_link}>
									{formatMono(detail.payment_link)}
								</BlankLink>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Creation Time</Table.HeaderCell>
              <Table.Cell>{formatEpoch(detail.created_at)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <DepositHistory deposits={deposits} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close} primary>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}
